<template>
  <onboarding-step :step="step">
    <loader color="primary" :is-loading="inProgress">
    <v-col cols='12'>
      <v-row>
        <v-list
        align-start>
          <v-list-item
            align-start
           >
            <v-list-item-icon>
              <v-icon :color="getColor(statePart.completed)">
                {{ getIcon(statePart.completed) }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                align="start">
                {{ $t(`onboarding.${this.step.name}.title`) }}
              </v-list-item-title>
              <v-list-item-subtitle
                align="start"
                v-if="!statePart.completed">
                {{ $t(`onboarding.${this.step.name}.noUser`) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-row>
       <v-divider></v-divider>
      <v-row>
        <v-col cols='6'>
          <validated-component
            name="givenName"
            v-model="givenName"
            :validator="$v.givenName">
            <v-text-field
              v-model="givenName"
              :label="$t(`onboarding.givenName`)"
              :placeholder="$t('onboarding.givenName')"
              outlined
              hide-details
              no-filter>
            </v-text-field>
          </validated-component>
        </v-col>
        <v-col cols='6'>
          <validated-component
            name="familyName"
            v-model="familyName"
            :validator="$v.familyName">
            <v-text-field
              v-model="familyName"
              :label="$t(`onboarding.familyName`)"
              :placeholder="$t('onboarding.familyName')"
              outlined
              hide-details
              no-filter>
            </v-text-field>
          </validated-component>
        </v-col>
        <v-col cols='6'>
          <validated-component
            name="email"
            v-model="email"
            :validator="$v.email">
            <v-text-field
              v-model="email"
              :label="$t(`onboarding.email`)"
              :placeholder="$t('onboarding.email')"
              outlined
              hide-details
              no-filter>
            </v-text-field>
          </validated-component>
        </v-col>
        <v-col cols='6'>
          <validated-component
            name="communicationLanguage"
            v-model="communicationLanguage"
            :validator="$v.communicationLanguage">
            <v-select
              v-model="communicationLanguage"
              :label="$t(`onboarding.communicationLanguage`)"
              :placeholder="$t('onboarding.communicationLanguage')"
              outlined
              :items="communicationLanguages"
              hide-details
              no-filter>
            </v-select>
          </validated-component>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col cols="4">
          <v-btn
            block
            large
            :disabled="$v.$invalid"
            class="header-button"
            color="primary"
            @click="createUser">
            {{ $t(`save`) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    </loader>
  </onboarding-step>
</template>

<script>
import userValidations from '../../validationDatas/userValidation';
import OnboardingStep from './OnboardingStep.vue';
import OnboardingStepMixin from '../../../mixins/onboardingStep.mixin';

export default {
  name: 'user-step',
  components: {
    OnboardingStep,
  },
  validations() {
    return userValidations;
  },
  mixins: [OnboardingStepMixin],
  props: {
    inProgress: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      communicationLanguages: [
        'fr',
        'en',
        'fi',
        'es',
      ],
      givenName: '',
      familyName: '',
      email: '',
      communicationLanguage: '',
    };
  },
  methods: {
    async createUser() {
      this.$emit('createUser', {
        communicationLanguages: this.communicationLanguages,
        givenName: this.givenName,
        familyName: this.familyName,
        email: this.email,
        communicationLanguage: this.communicationLanguage,
      });
    },
  },
};
</script>

<style scoped>
  .header-button {
    min-height: 55px;
  }
</style>
