<template>
    <div>
      <v-row>
        <v-col cols="12" v-if="jotForm">
          <v-alert
            dark
            color="success"
            border="right"
          >
            Pre-filled the form using data from : <strong>{{ jotForm.businessName }}</strong>
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col  cols=12>
          <h2>Profile</h2>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.humanReadableId"
            :validator="$v.bookingChannel.humanReadableId">
            <v-text-field
              autocomplete="off"
              v-model="bookingChannel.humanReadableId"
              label="Human Readable Id"
              outlined
              hide-details
              no-filter
            >
            </v-text-field>
          </validated-component>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.name"
            :validator="$v.bookingChannel.name">
            <v-text-field
              name="bookingChannelName"
              autocomplete="off"
              v-model="bookingChannel.name"
              label="Name"
              outlined
              hide-details
              no-filter
            >
            </v-text-field>
          </validated-component>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.communicationLanguage"
            :validator="$v.bookingChannel.communicationLanguage">
            <v-select
              v-model="bookingChannel.communicationLanguage"
              :items="defaultValues.languages"
              label="Communication Language"
              hide-details
              no-filter
              outlined
            ></v-select>
          </validated-component>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.location.latitude"
            :validator="$v.bookingChannel.location.latitude">
            <v-text-field
              autocomplete="off"
              v-model="bookingChannel.location.latitude"
              label="Latitude"
              outlined
              hide-details
              no-filter
            >
            </v-text-field>
          </validated-component>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.location.longitude"
            :validator="$v.bookingChannel.location.longitude">
            <v-text-field
              autocomplete="off"
              v-model="bookingChannel.location.longitude"
              label="Longitude"
              outlined
              hide-details
              no-filter
            >
            </v-text-field>
          </validated-component>
        </v-col>
      </v-row>
      <!-- Payment -->
      <v-row>
        <v-col cols="12">
          <h2>Payment</h2>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.preAuthorization.currency"
            :validator="$v.bookingChannel.preAuthorization.currency">
            <v-select
              v-model="bookingChannel.preAuthorization.currency"
              :items="defaultValues.currencies"
              label="Currency"
              hide-details
              no-filter
              outlined
            ></v-select>
          </validated-component>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.currencySymbol"
            :validator="$v.bookingChannel.currencySymbol">
            <v-select
              v-model="bookingChannel.currencySymbol"
              :items="defaultValues.currencySymbols"
              label="Currency Symbol"
              hide-details
              no-filter
              outlined
            ></v-select>
          </validated-component>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.defaultCountry"
            :validator="$v.bookingChannel.defaultCountry">
            <v-select
              v-model="bookingChannel.defaultCountry"
              :items="defaultValues.countries"
              label="Default Country"
              hide-details
              no-filter
              outlined
            ></v-select>
          </validated-component>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.defaultTip.defaultTipBase"
            :validator="$v.bookingChannel.defaultTip.defaultTipBase">
            <v-select
              v-model="bookingChannel.defaultTip.defaultTipBase"
              :items="defaultValues.tipBase"
              label="Default Tip Type"
              hide-details
              no-filter
              outlined
            ></v-select>
          </validated-component>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.defaultTip.defaultTipValue"
            :validator="$v.bookingChannel.defaultTip.defaultTipValue">
            <v-text-field
              autocomplete="off"
              v-model="bookingChannel.defaultTip.defaultTipValue"
              label="Default Tip Value"
              outlined
              hide-details
              no-filter
            ></v-text-field>
          </validated-component>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.preAuthorization.amount"
            :validator="$v.bookingChannel.preAuthorization.amount">
            <v-text-field
              min="0"
              type="number"
              v-model="bookingChannel.preAuthorization.amount"
              label="Pre-auth Amount"
              outlined
              hide-details
              no-filter
            ></v-text-field>
          </validated-component>
        </v-col>
      </v-row>
      <!-- Payment Method -->
      <v-row>
        <v-col cols="12">
          <h4 class="blue--text">Allowed Payment Methods</h4>
        </v-col>
        <v-col cols="12" v-if="isJotFormPaymentTypeAvailable">
          <v-alert
            border="top"
            colored-border
            type="success"
            elevation="2"
          >
            The selected Payment Type in the Jot Form is: <strong>{{ jotForm.paymentType }}</strong>
          </v-alert>
        </v-col>
        <v-col cols="12">
          <validated-component
            v-model="bookingChannel.allowedPaymentMethods"
            :validator="$v.bookingChannel.allowedPaymentMethods">
            <payment-methods-form v-model="bookingChannel.allowedPaymentMethods"></payment-methods-form>
          </validated-component>
        </v-col>
      </v-row>
      <div style="height: 2rem;"></div>
      <!-- Colors -->
      <v-row>
        <v-col  cols=12>
          <h2>Colors</h2>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.colorScheme.primary"
            :validator="$v.bookingChannel.colorScheme.primary">
            <color-preview :color="bookingChannel.colorScheme.primary"></color-preview>
            <v-text-field
              autocomplete="off"
              v-model="bookingChannel.colorScheme.primary"
              label="Primary Color"
              outlined
              hide-details
              no-filter
            ></v-text-field>
          </validated-component>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.colorScheme.primaryDark"
            :validator="$v.bookingChannel.colorScheme.primaryDark">
            <color-preview :color="bookingChannel.colorScheme.primaryDark"></color-preview>
            <v-text-field
              autocomplete="off"
              v-model="bookingChannel.colorScheme.primaryDark"
              label="Primary Dark Color"
              outlined
              hide-details
              no-filter
            ></v-text-field>
          </validated-component>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.colorScheme.primaryFont"
            :validator="$v.bookingChannel.colorScheme.primaryFont">
            <color-preview :color="bookingChannel.colorScheme.primaryFont"></color-preview>
            <v-text-field
              autocomplete="off"
              v-model="bookingChannel.colorScheme.primaryFont"
              label="Primary Font Color"
              outlined
              hide-details
              no-filter
            ></v-text-field>
          </validated-component>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.colorScheme.secondary"
            :validator="$v.bookingChannel.colorScheme.secondary">
            <color-preview :color="bookingChannel.colorScheme.secondary"></color-preview>
            <v-text-field
              autocomplete="off"
              v-model="bookingChannel.colorScheme.secondary"
              label="Secondary Color"
              outlined
              hide-details
              no-filter
            ></v-text-field>
          </validated-component>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.colorScheme.error"
            :validator="$v.bookingChannel.colorScheme.error">
            <color-preview :color="bookingChannel.colorScheme.error"></color-preview>
            <v-text-field
              autocomplete="off"
              v-model="bookingChannel.colorScheme.error"
              label="Error Color"
              outlined
              hide-details
              no-filter
            ></v-text-field>
          </validated-component>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.colorScheme.loginLogoBackground"
            :validator="$v.bookingChannel.colorScheme.loginLogoBackground">
            <color-preview :color="bookingChannel.colorScheme.loginLogoBackground"></color-preview>
            <v-text-field
              autocomplete="off"
              v-model="bookingChannel.colorScheme.loginLogoBackground"
              label="Login Logo Background Color"
              outlined
              hide-details
              no-filter
            ></v-text-field>
          </validated-component>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.colorScheme.appIconBackground"
            :validator="$v.bookingChannel.colorScheme.appIconBackground">
            <color-preview :color="bookingChannel.colorScheme.appIconBackground"></color-preview>
            <v-text-field
              autocomplete="off"
              v-model="bookingChannel.colorScheme.appIconBackground"
              label="App Icon Background Color"
              outlined
              hide-details
              no-filter
            ></v-text-field>
          </validated-component>
        </v-col>
        <v-col cols="12" md="4">
          <validated-component
            v-model="bookingChannel.colorScheme.loginActionBackground"
            :validator="$v.bookingChannel.colorScheme.loginActionBackground">
            <color-preview :color="bookingChannel.colorScheme.loginActionBackground"></color-preview>
            <v-text-field
              autocomplete="off"
              v-model="bookingChannel.colorScheme.loginActionBackground"
              label="Login Action Background Color"
              outlined
              hide-details
              no-filter
            ></v-text-field>
          </validated-component>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import _ from 'lodash';
import PaymentMethodsForm from '@/components/PaymentMethodsForm.vue';
import ColorPreview from '../customerData/ColorPreview.vue';
import bookingChannelValidations from '../validationDatas/createBookingChannelValidation';

export default {
  name: 'CreateBookingChannelForm',
  components: {
    ColorPreview,
    PaymentMethodsForm,
  },
  props: {
    bookingChannelSeed: {
      type: Object,
      required: true,
    },
    // If the user imports the data from the Jot Form,
    // we can store it here to display relevant information.
    // The `bookingChannelSeed` will be a subset of the Jot Form transformed
    // according the booking channel model.
    jotForm: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    defaultValues: {
      countries: ['CA', 'US', 'IE', 'UK', 'GB', 'AU', 'SE', 'FI', 'NZ', 'GY', 'NL', 'ES', 'BE'],
      currencies: ['AUD', 'CAD', 'USD', 'EUR', 'GBP', 'SEK', 'NZD', 'GYD'],
      currencySymbols: ['$', '€', '£', 'kr'],
      languages: ['en', 'fr', 'fi', 'nl', 'es'],
      tipBase: ['currency', 'percentage'],
    },
    bookingChannel: {},
  }),
  computed: {
    isJotFormPaymentTypeAvailable() {
      return this.jotForm && this.jotForm.paymentType;
    },
  },
  watch: {
    bookingChannel: {
      handler() {
        this.$emit('formChanged', { bookingChannel: this.bookingChannel, $v: this.$v });
      },
      deep: true,
    },
  },
  created() {
    this.setBookingChannelDefaults();
  },
  validations() {
    return { bookingChannel: bookingChannelValidations.bookingChannelData };
  },
  methods: {
    setBookingChannelDefaults() {
      this.bookingChannel = {
        logoFormat: '200x200',
        colorScheme: {
          primary: '#4caf50',
          primaryDark: '#4caf50',
          primaryFont: '#FFFFFF',
          secondary: '#4caf50',
          error: '#eb5757',
          appIconBackground: '#4caf50',
          loginLogoBackground: '#4caf50',
          loginActionBackground: '#FFFFFF',
        },
        location: {
          latitude: null,
          longitude: null,
        },
        defaultTip: {
          defaultTipBase: null,
          defaultTipValue: null,
        },
        allowedPaymentMethods: [],
        ...this.bookingChannelSeed,
        // Intentionally init the `preAuthorization` object AFTER
        // merging with bookingChannelSeed, if bookingChannelSeed came from the
        // JOT Form, the `amount` property will not be set (Jot Form does not contain the pre-auth field) and
        // will mess with the reactivity of the object.
        preAuthorization: {
          currency: _.get(this.bookingChannelSeed, 'preAuthorization.currency'),
          amount: null,
        },
      };
    },
  },
};
</script>

<style scoped>

</style>
