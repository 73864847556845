<template>
  <v-dialog :value="type" persistent width="80%">
    <v-card>
        <v-card-title>
          <span class="headline">{{ $t(`customerDataForm.${type}Title`)}}</span>
        </v-card-title>
        <v-card-text>
          <!-- information -->
          <v-row>
            <v-col  cols=12>
              <h2>{{ $t(`customerDataForm.subtitles.information`)}}</h2>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.humanReadableId"
                :validator="$v.customerData.humanReadableId">
                <v-text-field
                  v-model="customerData.humanReadableId"
                  :label="$t('customerDataForm.humanReadableId')"
                  outlined
                  hide-details
                  no-filter
                >
                </v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.name"
                :validator="$v.customerData.name">
                <v-text-field
                  v-model="customerData.name"
                  :label="$t('customerDataForm.name')"
                  outlined
                  hide-details
                  no-filter
                >
                </v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.id"
                :validator="$v.customerData.id">
                <v-text-field
                  v-model="customerData.id"
                  :label="$t('customerDataForm.id')"
                  outlined
                  hide-details
                  no-filter
                  readonly
                >
                </v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.communicationLanguage"
                :validator="$v.customerData.communicationLanguage">
                <v-select
                  v-model="customerData.communicationLanguage"
                  :items="defaultValues.languages"
                  :label="$t('customerDataForm.communicationLanguage')"
                  hide-details
                  no-filter
                  outlined
                ></v-select>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.latitude"
                :validator="$v.customerData.latitude">
                <v-text-field
                  v-model="customerData.latitude"
                  :label="$t('customerDataForm.latitude')"
                  outlined
                  hide-details
                  no-filter
                >
                </v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.longitude"
                :validator="$v.customerData.longitude">
                <v-text-field
                  v-model="customerData.longitude"
                  :label="$t('customerDataForm.longitude')"
                  outlined
                  hide-details
                  no-filter
                >
                </v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="6">
              <validated-component
                v-model="customerData.supportPhoneNumber"
                :validator="$v.customerData.supportPhoneNumber">
                <v-text-field
                  v-model="customerData.supportPhoneNumber"
                  :label="$t('customerDataForm.supportPhoneNumber')"
                  outlined
                  hide-details
                  no-filter
                >
                </v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="6">
              <validated-component
                v-model="customerData.callDriverDirectPhoneNumber"
                :validator="$v.customerData.callDriverDirectPhoneNumber">
                <v-text-field
                  v-model="customerData.callDriverDirectPhoneNumber"
                  :label="$t('customerDataForm.callDriverDirectPhoneNumber')"
                  outlined
                  hide-details
                  no-filter
                >
                </v-text-field>
              </validated-component>
            </v-col>
             <v-col cols="12" md="6">
              <validated-component
                v-model="customerData.dispatchName"
                :validator="$v.customerData.dispatchName">
                <v-text-field
                  v-model="customerData.dispatchName"
                  :label="$t('customerDataForm.dispatchName')"
                  outlined
                  hide-details
                  no-filter
                >
                </v-text-field>
              </validated-component>
            </v-col>
             <v-col cols="12" md="6">
              <validated-component
                v-model="customerData.companyId"
                :validator="$v.customerData.companyId">
                <v-text-field
                  v-model="customerData.companyId"
                  :label="$t('customerDataForm.companyId')"
                  outlined
                  hide-details
                  no-filter
                >
                </v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.prebookingEnabled"
                :validator="$v.customerData.prebookingEnabled">
                <v-select
                  v-model="customerData.prebookingEnabled"
                  :items="defaultValues.booleans"
                  :label="$t('customerDataForm.prebookingEnabled')"
                  hide-details
                  no-filter
                  outlined
                ></v-select>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.businessLoginEnabled"
                :validator="$v.customerData.businessLoginEnabled">
                <v-select
                  v-model="customerData.businessLoginEnabled"
                  :items="defaultValues.booleans"
                  :label="$t('customerDataForm.businessLoginEnabled')"
                  hide-details
                  no-filter
                  outlined
                ></v-select>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.personalLoginEnabled"
                :validator="$v.customerData.personalLoginEnabled">
                <v-select
                  v-model="customerData.personalLoginEnabled"
                  :items="defaultValues.booleans"
                  :label="$t('customerDataForm.personalLoginEnabled')"
                  hide-details
                  no-filter
                  outlined
                ></v-select>
              </validated-component>
            </v-col>
          </v-row>
          <!-- payment -->
          <v-row>
            <v-col  cols=12>
              <h2>{{ $t(`customerDataForm.subtitles.payment`)}}</h2>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.currency"
                :validator="$v.customerData.currency">
                <v-select
                  v-model="customerData.currency"
                  :items="defaultValues.currencies"
                  :label="$t('customerDataForm.currency')"
                  hide-details
                  no-filter
                  outlined
                ></v-select>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.currencySymbol"
                :validator="$v.customerData.currencySymbol">
                <v-select
                  v-model="customerData.currencySymbol"
                  :items="defaultValues.currencySymbols"
                  :label="$t('customerDataForm.currencySymbol')"
                  hide-details
                  no-filter
                  outlined
                ></v-select>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.defaultCountry"
                :validator="$v.customerData.defaultCountry">
                <v-text-field
                  v-model="customerData.defaultCountry"
                  :label="$t('customerDataForm.defaultCountry')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.paymentType"
                :validator="$v.customerData.paymentType">
                <v-text-field
                  v-model="customerData.paymentType"
                  :label="$t('customerDataForm.paymentType')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.defaultTipBase"
                :validator="$v.customerData.defaultTipBase">
                <v-select
                  v-model="customerData.defaultTipBase"
                  :items="defaultValues.tipBase"
                  :label="$t('customerDataForm.defaultTipBase')"
                  hide-details
                  no-filter
                  outlined
                ></v-select>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.defaultTipValue"
                :validator="$v.customerData.defaultTipValue">
                 <v-text-field
                  v-model="customerData.defaultTipValue"
                  :label="$t('customerDataForm.defaultTipValue')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.preAuthAmount"
                :validator="$v.customerData.preAuthAmount">
                 <v-text-field
                  v-model="customerData.preAuthAmount"
                  :label="$t('customerDataForm.preAuthAmount')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.percentageMaxAmount"
                :validator="$v.customerData.percentageMaxAmount">
                 <v-text-field
                  v-model="customerData.percentageMaxAmount"
                  :label="$t('customerDataForm.percentageMaxAmount')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.currencyMaxAmount"
                :validator="$v.customerData.currencyMaxAmount">
                 <v-text-field
                  v-model="customerData.currencyMaxAmount"
                  :label="$t('customerDataForm.currencyMaxAmount')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.cashEnabled"
                :validator="$v.customerData.cashEnabled">
                 <v-select
                  v-model="customerData.cashEnabled"
                  :items="defaultValues.booleans"
                  :label="$t('customerDataForm.cashEnabled')"
                  hide-details
                  no-filter
                  outlined
                ></v-select>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.applePayEnabled"
                :validator="$v.customerData.applePayEnabled">
                 <v-select
                  v-model="customerData.applePayEnabled"
                  :items="defaultValues.booleans"
                  :label="$t('customerDataForm.applePayEnabled')"
                  hide-details
                  no-filter
                  outlined
                ></v-select>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.googlePayEnabled"
                :validator="$v.customerData.googlePayEnabled">
                 <v-select
                  v-model="customerData.googlePayEnabled"
                  :items="defaultValues.booleans"
                  :label="$t('customerDataForm.googlePayEnabled')"
                  hide-details
                  no-filter
                  outlined
                ></v-select>
              </validated-component>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="customerData.salesTaxInfo"
                :validator="$v.customerData.salesTaxInfo">
                 <v-text-field
                  v-model="customerData.salesTaxInfo"
                  :label="$t('customerDataForm.salesTaxInfo')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
          </v-row>
          <!-- Passenger Apps -->
          <v-row>
            <v-col  cols=12>
              <h2>{{ $t(`customerDataForm.subtitles.passengerApps`)}}</h2>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.iosBundleId"
                :validator="$v.customerData.iosBundleId">
                <v-text-field
                  v-model="customerData.iosBundleId"
                  :label="$t('customerDataForm.iosBundleId')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.androidApplicationId"
                :validator="$v.customerData.androidApplicationId">
                <v-text-field
                  v-model="customerData.androidApplicationId"
                  :label="$t('customerDataForm.androidApplicationId')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.firstInstallPromptEnabled"
                :validator="$v.customerData.firstInstallPromptEnabled">
                <v-select
                  v-model="customerData.firstInstallPromptEnabled"
                  :items="defaultValues.booleans"
                  :label="$t('customerDataForm.firstInstallPromptEnabled')"
                  hide-details
                  no-filter
                  outlined
                ></v-select>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.googleMapsKey"
                :validator="$v.customerData.googleMapsKey">
                <v-text-field
                  v-model="customerData.googleMapsKey"
                  :label="$t('customerDataForm.googleMapsKey')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.storeUrlIos"
                :validator="$v.customerData.storeUrlIos">
                <v-text-field
                  v-model="customerData.storeUrlIos"
                  :label="$t('customerDataForm.storeUrlIos')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.teamId"
                :validator="$v.customerData.teamId">
                <v-text-field
                  v-model="customerData.teamId"
                  :label="$t('customerDataForm.teamId')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.iosProjectVersion"
                :validator="$v.customerData.iosProjectVersion">
                <v-text-field
                  v-model="customerData.iosProjectVersion"
                  :label="$t('customerDataForm.iosProjectVersion')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.iosMarketingVersion"
                :validator="$v.customerData.iosMarketingVersion">
                <v-text-field
                  v-model="customerData.iosMarketingVersion"
                  :label="$t('customerDataForm.iosMarketingVersion')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.androidVersionCode"
                :validator="$v.customerData.androidVersionCode">
                <v-text-field
                  v-model="customerData.androidVersionCode"
                  :label="$t('customerDataForm.androidVersionCode')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.androidVersionName"
                :validator="$v.customerData.androidVersionName">
                <v-text-field
                  v-model="customerData.androidVersionName"
                  :label="$t('customerDataForm.androidVersionName')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.appleAccountName"
                :validator="$v.customerData.appleAccountName">
                <v-text-field
                  v-model="customerData.appleAccountName"
                  :label="$t('customerDataForm.appleAccountName')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
             <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.googleAccountName"
                :validator="$v.customerData.googleAccountName">
                <v-text-field
                  v-model="customerData.googleAccountName"
                  :label="$t('customerDataForm.googleAccountName')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.isGooglePayInProduction"
                :validator="$v.customerData.isGooglePayInProduction">
                <v-select
                  v-model="customerData.isGooglePayInProduction"
                  :items="defaultValues.booleans"
                  :label="$t('customerDataForm.isGooglePayInProduction')"
                  hide-details
                  no-filter
                  outlined
                ></v-select>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.promoCodeEnabled"
                :validator="$v.customerData.promoCodeEnabled">
                <v-select
                  v-model="customerData.promoCodeEnabled"
                  :items="defaultValues.booleans"
                  :label="$t('customerDataForm.promoCodeEnabled')"
                  hide-details
                  no-filter
                  outlined
                ></v-select>
              </validated-component>
            </v-col>
          </v-row>
          <!-- Assets -->
          <v-row>
            <v-col  cols=12>
              <h2>{{ $t(`customerDataForm.subtitles.assets`)}}</h2>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.logoFormat"
                :validator="$v.customerData.logoFormat">
                <v-text-field
                  v-model="customerData.logoFormat"
                  :label="$t('customerDataForm.logoFormat')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.favicons"
                :validator="$v.customerData.favicons">
                <v-text-field
                  v-model="customerData.favicons"
                  :label="$t('customerDataForm.favicons')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData['ms-icons']"
                :validator="$v.customerData['ms-icons']">
                <v-text-field
                  v-model="customerData['ms-icons']"
                  :label="$t('customerDataForm.ms-icons')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.androidAssets"
                :validator="$v.customerData.androidAssets">
                <v-text-field
                  v-model="customerData.androidAssets"
                  :label="$t('customerDataForm.androidAssets')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.iosAssets"
                :validator="$v.customerData.iosAssets">
                <v-text-field
                  v-model="customerData.iosAssets"
                  :label="$t('customerDataForm.iosAssets')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.figmaExample"
                :validator="$v.customerData.figmaExample">
                <v-text-field
                  v-model="customerData.figmaExample"
                  :label="$t('customerDataForm.figmaExample')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
          </v-row>
          <!-- Admins -->
          <v-row>
            <v-col  cols=12>
              <h2>{{ $t(`customerDataForm.subtitles.admins`)}}</h2>
            </v-col>
            <v-col cols="12">
              <validated-component
                v-model="customerData.stripeAdmin"
                :validator="$v.customerData.stripeAdmin">
                <v-text-field
                  v-model="customerData.stripeAdmin"
                  :label="$t('customerDataForm.stripeAdmin')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="6">
              <validated-component
                v-model="customerData.businessAdminFullName"
                :validator="$v.customerData.businessAdminFullName">
                <v-text-field
                  v-model="customerData.businessAdminFullName"
                  :label="$t('customerDataForm.businessAdminFullName')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="6">
              <validated-component
                v-model="customerData.businessAdminEmail"
                :validator="$v.customerData.businessAdminEmail">
                <v-text-field
                  v-model="customerData.businessAdminEmail"
                  :label="$t('customerDataForm.businessAdminEmail')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="6">
              <validated-component
                v-model="customerData.customerConsoleAdminFullName"
                :validator="$v.customerData.customerConsoleAdminFullName">
                <v-text-field
                  v-model="customerData.customerConsoleAdminFullName"
                  :label="$t('customerDataForm.customerConsoleAdminFullName')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="6">
              <validated-component
                v-model="customerData.customerConsoleAdminEmail"
                :validator="$v.customerData.customerConsoleAdminEmail">
                <v-text-field
                  v-model="customerData.customerConsoleAdminEmail"
                  :label="$t('customerDataForm.customerConsoleAdminEmail')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
          </v-row>
          <!-- Colors -->
          <v-row>
            <v-col  cols=12>
              <h2>{{ $t(`customerDataForm.subtitles.colors`)}}</h2>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.primaryColor"
                :validator="$v.customerData.primaryColor">
                <color-preview :color="customerData.primaryColor"></color-preview>
                <v-text-field
                  v-model="customerData.primaryColor"
                  :label="$t('customerDataForm.primaryColor')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.primaryDarkColor"
                :validator="$v.customerData.primaryDarkColor">
                <color-preview :color="customerData.primaryDarkColor"></color-preview>
                <v-text-field
                  v-model="customerData.primaryDarkColor"
                  :label="$t('customerDataForm.primaryDarkColor')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.primaryFontColor"
                :validator="$v.customerData.primaryFontColor">
                <color-preview :color="customerData.primaryFontColor"></color-preview>
                <v-text-field
                  v-model="customerData.primaryFontColor"
                  :label="$t('customerDataForm.primaryFontColor')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.secondaryColor"
                :validator="$v.customerData.secondaryColor">
                <color-preview :color="customerData.secondaryColor"></color-preview>
                <v-text-field
                  v-model="customerData.secondaryColor"
                  :label="$t('customerDataForm.secondaryColor')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.errorColor"
                :validator="$v.customerData.errorColor">
                <color-preview :color="customerData.errorColor"></color-preview>
                <v-text-field
                  v-model="customerData.errorColor"
                  :label="$t('customerDataForm.errorColor')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.loginLogoBackgroundColor"
                :validator="$v.customerData.loginLogoBackgroundColor">
                <color-preview :color="customerData.loginLogoBackgroundColor"></color-preview>
                <v-text-field
                  v-model="customerData.loginLogoBackgroundColor"
                  :label="$t('customerDataForm.loginLogoBackgroundColor')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
            <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.appIconBackgroundColor"
                :validator="$v.customerData.appIconBackgroundColor">
                <color-preview :color="customerData.appIconBackgroundColor"></color-preview>
                <v-text-field
                  v-model="customerData.appIconBackgroundColor"
                  :label="$t('customerDataForm.appIconBackgroundColor')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
             <v-col cols="12" md="4">
              <validated-component
                v-model="customerData.loginActionBackgroundColor"
                :validator="$v.customerData.loginActionBackgroundColor">
                <color-preview :color="customerData.loginActionBackgroundColor"></color-preview>
                <v-text-field
                  v-model="customerData.loginActionBackgroundColor"
                  :label="$t('customerDataForm.loginActionBackgroundColor')"
                  outlined
                  hide-details
                  no-filter
                ></v-text-field>
              </validated-component>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="close()">
            {{$t('customerDataForm.close')}}
          </v-btn>
          <v-btn color="success" :disabled="disabled" v-if="isCreate" @click="saveItem()">
            {{$t('customerDataForm.create')}}
          </v-btn>
          <v-btn
            color="success"
            v-if="isEdit"
            :disabled="disabled"
            @click="editItem()"
          >
            {{$t('customerDataForm.edit')}}
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import ColorPreview from './ColorPreview.vue';
import customerDataValidations from '../validationDatas/customerData';

export default {
  name: 'customer-data-mutation',
  props: ['type', 'customerData'],
  components: { ColorPreview },
  data: () => ({
    defaultValues: {
      countries: ['CA', 'US', 'IE', 'UK', 'GB', 'AU', 'SE', 'FI', 'NZ', 'GY', 'ES', 'BE'],
      currencies: ['AUD', 'CAD', 'USD', 'EUR', 'GBP', 'SEK', 'NZD'],
      currencySymbols: ['$', '€', '£', 'kr'],
      languages: ['en', 'fr', 'fi', 'es'],
      booleans: [false, true],
      tipBase: ['currency', 'percentage'],
    },
  }),
  computed: {
    isEdit() {
      return this.type === 'edit';
    },
    isCreate() {
      return this.type === 'create';
    },
    disabled() {
      this.$v.$touch();
      return this.$v.$invalid;
    },
  },
  validations() {
    return { customerData: customerDataValidations.customerData };
  },
  methods: {
    async saveItem() {
      await this.$store.dispatch('customerData/save', this.customerData);
      this.$emit('updateParent');
      this.$emit('close');
    },
    async editItem() {
      await this.$store.dispatch('customerData/edit', this.customerData);
      this.$emit('updateParent');
      this.$emit('close');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
