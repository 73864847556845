<template>
  <div>
    <v-alert type="error" v-if="isDisplayFormError" class="form-error-alert">
      The form is invalid. Please correct the errors.
    </v-alert>
    <v-row>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.name"
          :validator="$v.bookingChannel.name">
          <v-text-field
            name="bookingChannelName"
            autocomplete="off"
            v-model="bookingChannel.name"
            label="Name"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.communicationLanguage"
          :validator="$v.bookingChannel.communicationLanguage">
          <v-select
            v-model="bookingChannel.communicationLanguage"
            :items="defaultValues.languages"
            label="Communication Language"
            hide-details
            no-filter
            outlined
          ></v-select>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.defaultCountry"
          :validator="$v.bookingChannel.defaultCountry">
          <v-select
            v-model="bookingChannel.defaultCountry"
            :items="defaultValues.countries"
            label="Default Country"
            hide-details
            no-filter
            outlined
          ></v-select>
        </validated-component>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.location.latitude"
          :validator="$v.bookingChannel.location.latitude">
          <v-text-field
            autocomplete="off"
            v-model="bookingChannel.location.latitude"
            label="Latitude"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.location.longitude"
          :validator="$v.bookingChannel.location.longitude">
          <v-text-field
            autocomplete="off"
            v-model="bookingChannel.location.longitude"
            label="Longitude"
            outlined
            hide-details
            no-filter
          >
          </v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <!-- Payment -->
    <v-row>
      <v-col cols="12">
        <h2>Payment</h2>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.preAuthorization.currency"
          :validator="$v.bookingChannel.preAuthorization.currency">
          <v-select
            v-model="bookingChannel.preAuthorization.currency"
            :items="defaultValues.currencies"
            label="Currency"
            hide-details
            no-filter
            outlined
          ></v-select>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.currencySymbol"
          :validator="$v.bookingChannel.currencySymbol">
          <v-select
            v-model="bookingChannel.currencySymbol"
            :items="defaultValues.currencySymbols"
            label="Currency Symbol"
            hide-details
            no-filter
            outlined
          ></v-select>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.preAuthorization.amount"
          :validator="$v.bookingChannel.preAuthorization.amount">
          <v-text-field
            min="0"
            type="number"
            :value="bookingChannel.preAuthorization.amount"
            @input="bookingChannel.preAuthorization.amount = Number($event)"
            label="Pre-auth Amount"
            outlined
            hide-details
            no-filter
          ></v-text-field>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.defaultTip.defaultTipBase"
          :validator="$v.bookingChannel.defaultTip.defaultTipBase">
          <v-select
            v-model="bookingChannel.defaultTip.defaultTipBase"
            :items="defaultValues.tipBase"
            label="Default Tip Type"
            hide-details
            no-filter
            outlined
          ></v-select>
        </validated-component>
      </v-col>
      <v-col cols="12" md="4">
        <validated-component
          v-model="bookingChannel.defaultTip.defaultTipValue"
          :validator="$v.bookingChannel.defaultTip.defaultTipValue">
          <v-text-field
            autocomplete="off"
            :value="bookingChannel.defaultTip.defaultTipValue"
            @input="bookingChannel.defaultTip.defaultTipValue = Number($event)"
            label="Default Tip Value"
            outlined
            hide-details
            no-filter
          ></v-text-field>
        </validated-component>
      </v-col>
    </v-row>
    <!-- Payment Method -->
    <v-row>
      <v-col cols="12">
        <h4 class="blue--text">Allowed Payment Methods</h4>
      </v-col>
      <v-col cols="12">
        <validated-component
          v-model="bookingChannel.allowedPaymentMethods"
          :validator="$v.bookingChannel.allowedPaymentMethods">
          <payment-methods-form ref="paymentMethodsForm" v-model="bookingChannel.allowedPaymentMethods"></payment-methods-form>
        </validated-component>
      </v-col>
    </v-row>
    <div style="height: 2rem;"></div>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-end">
          <v-btn color="success" @click="onSaveClick" :disabled="!canSave">Save</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  minLength, maxLength, required, decimal, integer,
} from 'vuelidate/lib/validators';
import { bookingChannelVuelidate } from '@/components/validationDatas/bookingChannelValidationRules';
import PaymentMethodsForm from '@/components/PaymentMethodsForm.vue';

export default {
  name: 'ProfileSettingsForm',
  components: { PaymentMethodsForm },
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultValues: {
        countries: ['CA', 'US', 'IE', 'UK', 'GB', 'AU', 'SE', 'FI', 'NZ', 'GY', 'NL', 'ES', 'BE'],
        currencies: ['AUD', 'CAD', 'USD', 'EUR', 'GBP', 'SEK', 'NZD', 'GYD'],
        currencySymbols: ['$', '€', '£', 'kr'],
        languages: ['en', 'fr', 'fi', 'nl', 'es'],
        tipBase: ['currency', 'percentage'],
      },
      bookingChannel: _.cloneDeep(this.remoteState.bookingChannel),
      isDisplayFormError: false,
    };
  },
  methods: {
    async onSaveClick() {
      this.isDisplayFormError = false;
      this.$v.$touch();
      if (this.hasErrors()) {
        this.isDisplayFormError = true;
      } else {
        await this.save();
      }
    },
    hasErrors() {
      return this.$v.$invalid;
    },
    async save() {
      await this.$store.dispatch('bookingChannelView/updateBookingChannel', {
        id: this.remoteState.bookingChannel.id,
        payload: this.bookingChannel,
      });

      this.$refs.paymentMethodsForm.preselectExistingPaymentMethods();
    },
  },
  computed: {
    canSave() {
      return this.$v.$anyDirty;
    },
  },
  validations() {
    return {
      bookingChannel: {
        name: bookingChannelVuelidate.name,
        currencySymbol: bookingChannelVuelidate.currencySymbol,
        defaultCountry: bookingChannelVuelidate.defaultCountry,
        communicationLanguage: bookingChannelVuelidate.communicationLanguage,
        location: bookingChannelVuelidate.location,
        preAuthorization: {
          amount: {
            required,
            integer,
          },
          currency: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(255),
          },
        },
        defaultTip: {
          required,
          defaultTipValue: {
            decimal,
          },
          defaultTipBase: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(255),
          },
        },
        allowedPaymentMethods: {
          required,
          minLength: minLength(1),
          $each: {
            type: {
              required,
              minLength: minLength(1),
            },
          },
        },
      },
    };
  },
};
</script>

<style scoped>

</style>
