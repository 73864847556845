import Joi from 'joi';
import FileUploadUtil from '@/util/FileUploadUtil';

const COLOR_SCHEME = Joi.string().regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);

const joiValidateAndReturnErrors = ({ payload, schema, target = null }) => {
  const { error } = schema.validate(payload);
  if (error) {
    if (target) {
      // eslint-disable-next-line no-console
      console.log(`[BookingChannelFormResolver] Joi validation error for target ${target}: `, {
        error,
        payload,
      });
    }
    return error.details.map(e => e.message);
  }
  return [];
};

const mappings = Object.freeze({
  profile: {
    code: 'profile',
    display: 'Profile',
    component: 'profile-settings-form',
    getErrors: ({ bookingChannel }) => {
      const schema = Joi.object().keys({
        name: Joi.string().required(),
        defaultCountry: Joi.string().required(),
        currencySymbol: Joi.string().required(),
        communicationLanguage: Joi.string().valid('fr', 'en', 'fi', 'es'),
        location: Joi.object().keys({
          latitude: Joi.number().required(),
          longitude: Joi.number().required(),
        }).required(),
        allowedPaymentMethods: Joi.array().items(Joi.object().keys({
          type: Joi.string().required(),
        })).required(),
        defaultTip: Joi.object().keys({
          defaultTipValue: Joi.number().required(),
          defaultTipBase: Joi.string().required(),
        }).required(),
      }).unknown(true);

      return joiValidateAndReturnErrors({ payload: bookingChannel, schema });
    },
  },
  theme: {
    code: 'theme',
    display: 'Theme',
    component: 'theme-form',
    getErrors: ({ bookingChannel }) => {
      const schema = Joi.object().keys({
        logoFormat: Joi.string().required(),
        colorScheme: Joi.object().keys({
          primary: COLOR_SCHEME,
          primaryDark: COLOR_SCHEME,
          secondary: COLOR_SCHEME,
          primaryFont: COLOR_SCHEME,
          secondaryFont: COLOR_SCHEME,
          accent: COLOR_SCHEME,
          error: COLOR_SCHEME,
          info: COLOR_SCHEME,
          success: COLOR_SCHEME,
          warning: COLOR_SCHEME,
          loginLogoBackground: COLOR_SCHEME,
          loginActionBackground: COLOR_SCHEME,
          appIconBackground: COLOR_SCHEME,
        }).required(),
      }).unknown(true);

      return joiValidateAndReturnErrors({ payload: bookingChannel, schema });
    },
  },
  fleetAssociations: {
    code: 'fleetAssociations',
    display: 'Fleet Associations',
    component: 'fleets-form',
    getErrors: ({ bookingChannel }) => {
      const schema = Joi.object().keys({
        fleets: Joi.array().min(1).items(
          Joi.object().keys({
            id: Joi.string().guid().required(),
            type: Joi.string().valid('partner', 'standard').required(),
            priority: Joi.string().valid('high', 'standard').optional(),
          }).unknown(true),
        ).required(),
      }).unknown(true);

      return joiValidateAndReturnErrors({ payload: bookingChannel, schema });
    },
  },
  fleetNetworks: {
    code: 'fleetNetworks',
    display: 'Networks',
    component: 'fleet-networks-form',
    getErrors: () => [],
  },
  businessSolution: {
    code: 'businessSolution',
    display: 'Business Solution',
    component: 'business-solution-form',
    getErrors: ({ checkState }) => {
      const errors = [];
      if (!checkState.businessSolution.completed) {
        errors.push('The Business Solution component is not completed');
      }

      return errors;
    },
  },
  customerConsole: {
    code: 'customerConsole',
    display: 'Customer Console',
    component: 'customer-console-form',
    // eslint-disable-next-line no-unused-vars
    getErrors: ({ checkState }) => {
      const errors = [];
      if (!checkState.customerConsole.completed) {
        errors.push('The Customer Console component is not completed');
      }

      return errors;
    },
  },
  marketingConsole: {
    code: 'marketingConsole',
    display: 'Marketing Console',
    component: 'marketing-console-form',
    getErrors: ({ checkState }) => {
      const errors = [];
      if (!checkState.marketingConsole.completed) {
        errors.push('The Marketing Console component is not completed');
      }

      return errors;
    },
  },
  appBuildSettings: {
    code: 'appBuildSettings',
    display: 'App Build Settings',
    component: 'app-build-settings-form',
    // eslint-disable-next-line no-unused-vars
    getErrors: ({ appConfig }) => {
      if (!appConfig) {
        return ['The App Build Settings component is not completed '];
      }

      const schema = Joi.object().keys({
        iosMinimumSupportedProductVersion: Joi.string().optional(),
        iosBundleId: Joi.string().required(),
        iosProjectVersion: Joi.number().required(),
        iosMarketingVersion: Joi.string().required(),
        iosTeamId: Joi.string().required(),
        isUniversalApp: Joi.boolean().optional(),
        appleAccountName: Joi.string().required(),
        iosGoogleMapsKey: Joi.string().required(),
        androidApplicationId: Joi.string().required(),
        androidMinimumSupportedProductVersion: Joi.string().optional(),
        androidVersionCode: Joi.number().required(),
        androidVersionName: Joi.string().required(),
        androidGoogleMapsKey: Joi.string().required(),
        androidClientLoggerKey: Joi.string().allow('').optional(),
        iosClientLoggerKey: Joi.string().allow('').optional(),
        googleAccountName: Joi.string().required(),
        isGooglePayInProduction: Joi.boolean().required(),
        supportPhoneNumber: Joi.string().optional(),
        callDriverDirectPhoneNumber: Joi.string().optional(),
        percentageMaxAmount: Joi.number().required(),
        currencyMaxAmount: Joi.number().required(),
        businessLoginEnabled: Joi.boolean().optional(),
        personalLoginEnabled: Joi.boolean().required(),
        prebookingEnabled: Joi.boolean().required(),
        firstInstallPromptEnabled: Joi.boolean().required(),
        promoCodeEnabled: Joi.boolean().required(),
        favicons: Joi.string().optional(),
        msIcons: Joi.string().optional(),
        androidAssets: Joi.string().optional(),
        iosAssets: Joi.string().optional(),
        figmaExample: Joi.string().optional(),
        feedbackEmails: Joi.array().items(Joi.string()).optional(),
      }).unknown(true);

      return joiValidateAndReturnErrors({ payload: appConfig, schema, target: 'APP_BUILD_SETTINGS_FORM' });
    },
  },
  appSigning: {
    code: 'appSigning',
    display: 'App Signing',
    component: 'app-signing-form',
    // eslint-disable-next-line no-unused-vars
    getErrors: ({ checkState }) => {
      const errors = [];
      if (!checkState.appSigning.completed) {
        errors.push('The Passenger App Signing component is not completed');
      }

      return errors;
    },
  },
  oidcClients: {
    code: 'oidcClients',
    display: 'OpenID Connect Clients',
    component: 'oidc-clients-form',
    // eslint-disable-next-line no-unused-vars
    getErrors: ({ checkState }) => {
      const errors = [];
      if (!checkState.oidcClients.completed) {
        errors.push('The OIDC Clients component is not completed');
      }

      return errors;
    },
  },
  appPreview: {
    code: 'appPreview',
    display: 'App Preview',
    component: 'app-preview-form',
    // eslint-disable-next-line no-unused-vars
    getErrors: remoteState => [],
  },
  paymentConnector: {
    code: 'paymentConnector',
    display: 'Payment Connector',
    component: 'payment-connector-form',
    getErrors: ({ bookingChannel }) => {
      const schema = Joi.object().keys({
        connectors: Joi.object().keys({
          payment: Joi.array().items(Joi.object().keys({
            name: Joi.string().required(),
            configuration: Joi.object().keys({
              apiKey: Joi.string().required(),
              testApiKey: Joi.string().required(),
              testAccountId: Joi.string().required(),
              isTestEnabled: Joi.boolean().required(),
              whitelistedTestUserIds: Joi.array().items(Joi.string()).required(),
              providerId: Joi.string().required(),
              creditCardBlocklistId: Joi.string().required(),
              failureWebhookSigningSecret: Joi.string().required(),
              successWebhookSigningSecret: Joi.string().required(),
              setupIntentFailureSigningSecret: Joi.string().required(),
              fundsWithdrawnWebhookSigningSecret: Joi.string().required(),
              maxAmount: Joi.number().integer(),
            }).required().unknown(),
            uiConfiguration: Joi.object().keys({
              publishableKey: Joi.string().required(),
              testPublishableKey: Joi.string().required(),
            }).required().unknown(),
          }).required().unknown(true)).min(1).required(),
        }).required().unknown(true),
      }).unknown(true);
      return joiValidateAndReturnErrors({ payload: bookingChannel, schema });
    },
  },
  notificationsConnector: {
    code: 'notificationsConnector',
    display: 'Notifications Connector',
    component: 'notification-form',
    getErrors: ({ checkState }) => {
      const errors = [];
      if (!checkState.pushNotification.completed) {
        errors.push('The Notifications Connector component is not completed');
      }

      return errors;
    },
  },
  userConnector: {
    code: 'userConnector',
    display: 'User Connector',
    component: 'user-connector-form',
    getErrors: ({ bookingChannel }) => {
      const schema = Joi.object().keys({
        connectors: Joi.object().keys({
          user: Joi.object().keys({
            name: Joi.string().required(),
            configuration: Joi.object().keys({
              serverKey: Joi.string().required(),
              serviceAccount: Joi.object().required(),
            }).unknown(true).required(),
            uiConfiguration: Joi.object().keys({
              apiKey: Joi.string().required(),
              projectId: Joi.string().required(),
              authDomain: Joi.string().required(),
              storageBucket: Joi.string().required(),
              messagingSenderId: Joi.string().required(),
            }).unknown(true).required(),
          }).required().unknown(true),
        }).required().unknown(true),
      }).unknown(true);

      return joiValidateAndReturnErrors({ payload: bookingChannel, schema });
    },
  },
  geoConnector: {
    code: 'geoConnector',
    display: 'Geo Connector',
    component: 'geo-connector-form',
    getErrors: ({ bookingChannel }) => {
      const schema = Joi.object().keys({
        connectors: Joi.object().keys({
          geo: Joi.object().keys({
            name: Joi.string().valid('googleMaps').required(),
            uiConfiguration: Joi.object().keys({
              accessToken: Joi.string().required(),
              mapStyle: Joi.string().required(),
            }).unknown(true).required(),
            driverConfigurations: Joi.array().items(Joi.object().keys({
              driverName: Joi.string().required(),
              configuration: Joi.object().keys({
                key: Joi.string().required(),
              }).unknown(true).optional(),
            })).required(),
          }).required().unknown(true),
        }).required().unknown(true),
      }).unknown(true);

      return joiValidateAndReturnErrors({ payload: bookingChannel, schema });
    },
  },
  features: {
    code: 'features',
    display: 'Features',
    component: 'features-form',
    // eslint-disable-next-line no-unused-vars
    getErrors: ({ bookingChannel }) => {
      const schema = Joi.object().keys({
        features: Joi.object().keys({
          appReview: Joi.boolean().optional(),
          qaAutomation: Joi.boolean().optional(),
          smsPersonalLogin: Joi.boolean().optional(),
          displayPricingInBusinessReporting: Joi.boolean().optional(),
          pairAndPay: Joi.object().keys({
            status: Joi.string().valid('active', 'inactive').required(),
            clientVersions: Joi.array().items(
              Joi.object().keys({
                platform: Joi.string().valid('ios', 'android', 'webbooker').required(),
                minimumVersion: Joi.string().required(),
              }),
            ).required(),
          }).optional(),
          delivery: Joi.object().keys({
            status: Joi.string().valid('active', 'inactive').required(),
            clientVersions: Joi.array().items(
              Joi.object().keys({
                platform: Joi.string().valid('ios', 'android', 'webbooker', 'business-solution-console', 'customer-console', 'customer-onboarding-service', 'marketing-console').required(),
                minimumVersion: Joi.string().required(),
              }),
            ).required(),
          }).optional(),
          customSelectField: Joi.object().keys({
            status: Joi.string().valid('active', 'inactive').required(),
            clientVersions: Joi.array().items(
              Joi.object().keys({
                platform: Joi.string().valid('ios', 'android', 'webbooker', 'business-solution-console', 'customer-console', 'customer-onboarding-service', 'marketing-console').required(),
                minimumVersion: Joi.string().required(),
              }),
            ).required(),
          }).optional(),
          icabbiGeoDriver: Joi.object().keys({
            status: Joi.string().valid('active', 'inactive').required(),
            clientVersions: Joi.array().items(
              Joi.object().keys({
                platform: Joi.string().valid('ios', 'android', 'webbooker', 'business-solution-console', 'customer-console', 'customer-onboarding-service', 'marketing-console').required(),
                minimumVersion: Joi.string().required(),
              }),
            ).required(),
          }).optional(),
          googlePay: Joi.object().keys({
            status: Joi.string().valid('active', 'inactive').required(),
            clientVersions: Joi.array().items(
              Joi.object().keys({
                platform: Joi.string().valid('ios', 'android', 'webbooker', 'business-solution-console', 'customer-console', 'customer-onboarding-service', 'marketing-console').required(),
                minimumVersion: Joi.string().required(),
              }),
            ).required(),
          }).unknown(true).optional(),
          thirdPartyDelivery: Joi.object().keys({
            status: Joi.string().valid('active', 'inactive').required(),
            iconUrl: Joi.string().optional(),
            url: Joi.string().required(),
            clientVersions: Joi.array().items(
              Joi.object().keys({
                platform: Joi.string().valid('ios', 'android', 'webbooker', 'business-solution-console', 'customer-console', 'customer-onboarding-service', 'marketing-console').required(),
                minimumVersion: Joi.string().required(),
              }),
            ).required(),
          }).unknown(true).optional(),
          isAddressViasFeatureEnabled: Joi.boolean().optional(),
          driverJobApplicationUrl: Joi.string().uri().optional(),
          isPostTripTipsEnabled: Joi.boolean().optional(),
          allowDuplicateBookings: Joi.boolean().optional(),
          isPromoCodeEnabled: Joi.boolean().optional(),
          isPreBookingEnabled: Joi.boolean().required(),
          airportPickupSettings: Joi.object().keys({
            canSkip: Joi.boolean().required(),
          }).required(),
          iosLiveActivity: Joi.object().keys({
            shouldUseEta: Joi.boolean().required(),
          }).optional(),
        }).optional(),
      }).unknown(true);

      return joiValidateAndReturnErrors({ payload: bookingChannel, schema });
    },
  },
  addressSearchConfiguration: {
    code: 'addressSearchConfiguration',
    display: 'Address Search Configuration',
    component: 'address-search-configuration-form',
    // eslint-disable-next-line no-unused-vars
    getErrors: ({ bookingChannel }) => {
      const schema = Joi.object().keys({
        addressSearchConfiguration: Joi.object().keys({
          radiusConfiguration: Joi.object().keys({
            defaultRadius: Joi.number().allow(null).required(),
          }).optional(),
          useGoogleAddressSearch: Joi.boolean().optional(),
        }).optional(),
      }).unknown(true);

      return joiValidateAndReturnErrors({ payload: bookingChannel, schema });
    },
  },
  alerts: {
    code: 'alerts',
    display: 'Alerts',
    component: 'alerts-configuration-form',
    // eslint-disable-next-line no-unused-vars
    getErrors: ({ bookingChannel }) => {
      const schema = Joi.object().keys({
        alerts: Joi.array().items(Joi.object().keys({
          type: Joi.string().valid('INFO', 'WARNING', 'ERROR').required(),
          title: Joi.string().required(),
          text: Joi.string().required(),
        })).optional(),
      }).unknown(true);

      return joiValidateAndReturnErrors({ payload: bookingChannel, schema });
    },
  },
  firebaseAnalyticsConfig: {
    code: 'firebaseAnalyticsConfig',
    display: 'Firebase Analytics Configuration',
    component: 'firebase-analytics-config-form',
    getErrors: ({ firebaseAnalyticsConfigState }) => {
      const validationSchemaForPlatform = ({ platform }) => {
        const supportedFileExtension = platform === 'android' ? 'xml' : 'plist';
        return Joi.object().keys({
          configType: Joi.string().valid('default', 'custom').required(),
          newFile: Joi.when('configType', {
            is: 'custom',
            then: Joi.when('hasUploadedFile', {
              is: false,
              then: Joi.custom((value, helpers) => {
                if (!value) {
                  return helpers.error('any.required');
                }
                const fileExtension = FileUploadUtil.getUploadedFileExtension(value);
                if (fileExtension !== supportedFileExtension) {
                  return helpers.error('file.validation');
                }
                return value;
              }, 'file validation'),
              otherwise: Joi.optional(),
            }),
            otherwise: Joi.optional(),
          }).messages({
            'any.required': `Please upload a valid configuration file for ${platform}`,
            'file.validation': `Please upload a valid ${supportedFileExtension} file for ${platform}`,
          }),
        }).unknown(true);
      };

      const schema = Joi.object().keys({
        android: validationSchemaForPlatform({ platform: 'android' }),
        ios: validationSchemaForPlatform({ platform: 'ios' }),
      }).unknown(true);

      return joiValidateAndReturnErrors({ payload: firebaseAnalyticsConfigState, schema });
    },
  },
  firebaseWebbookerAnalytics: {
    code: 'firebaseWebbookerAnalytics',
    display: 'Firebase Webbooker Analytics',
    component: 'firebase-webbooker-analytics-form',
    getErrors: ({ firebaseWebbookerAnalyticsState }) => {
      const schema = Joi.object().keys({
        apiKey: Joi.string().required(),
        projectId: Joi.string().required(),
        appId: Joi.string().required(),
        messagingSenderId: Joi.string().required(),
      }).unknown(true).optional();
      return joiValidateAndReturnErrors({ payload: firebaseWebbookerAnalyticsState, schema });
    },
  },
});


export default {
  getFormDefinitionFromCode(code) {
    return mappings[code];
  },
  getAllForms() {
    return mappings;
  },
};
