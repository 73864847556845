import axios from 'axios';
import {
  get, isEmpty, isArray, isNil,
} from 'lodash';
import { DispatchEngine } from '@/core/dispatchEngine';
import { Authentication } from '@icabbi/vue-authentication';
import pMap from 'p-map';

const defaultState = {
  list: [],
  listFleetInProgress: false,
  onboardingApiServiceClient: null,
  selectedFleetBookingChannelConfig: null,
  dispatchAttributeInProgress: false,
  saveFleetInProgress: false,
  updateFleetInProgress: false,
  retrieveFleetInProgress: false,
};

const mapFleetPayload = (fleetData) => {
  const fleetPayload = Object.assign({}, fleetData);
  delete fleetPayload.dispatchInformation;
  fleetPayload.taxes.enabled = fleetPayload.taxes.enabled || false;
  fleetPayload.connectors.payment.forEach((connector, index) => {
    fleetPayload.connectors.payment[index].configuration.fees = {
      fixed: parseInt(fleetData.connectors.payment[index].configuration.fees.fixed, 10),
      percentage: parseFloat(fleetData.connectors.payment[index].configuration.fees.percentage, 1),
    };
  });
  if (get(fleetPayload, 'credentials.dispatchAttributes.dispatchId') === DispatchEngine.MOBILE_KNOWLEDGE) {
    fleetPayload.dispatchInformation.coordinates = {
      latitude: parseFloat(fleetPayload.dispatchInformation.coordinates.latitude),
      longitude: parseFloat(fleetPayload.dispatchInformation.coordinates.longitude),
    };
  }
  if (!isEmpty(get(fleetPayload, 'networkCoordinates'))) {
    fleetPayload.networkCoordinates = {
      latitude: parseFloat(fleetPayload.networkCoordinates.latitude),
      longitude: parseFloat(fleetPayload.networkCoordinates.longitude),
    };
  }
  if (!isNil(get(fleetPayload, 'networkRadius'))) {
    fleetPayload.networkRadius = parseFloat(fleetPayload.networkRadius);
  }
  return fleetPayload;
};

const getters = {
  list: state => state.list,
  listFleetInProgress: state => state.listFleetInProgress,
  saveFleetInProgress: state => state.saveFleetInProgress,
  selectedFleetBookingChannelConfig: state => state.selectedFleetBookingChannelConfig,
  updateFleetInProgress: state => state.updateFleetInProgress,
  retrieveFleetInProgress: state => state.retrieveFleetInProgress,
  onboardingApiServiceClient: (state, contextGetters, rootState, rootGetters) => ({ dispatch }) => {
    const authentication = Authentication({ dispatch, getters: rootGetters });
    const { authenticationFailureInterceptor, bearerTokenInterceptor } = authentication;
    if (state.onboardingApiServiceClient === null) {
      state.onboardingApiServiceClient = axios.create({
        baseURL: process.env.VUE_APP_ONBOARDING_SERVICE_BASE_URL,
        timeout: 30000,
      });
      state.onboardingApiServiceClient.interceptors.response.use(
        undefined, error => authenticationFailureInterceptor(error),
      );
      state.onboardingApiServiceClient.interceptors.request.use(
        config => bearerTokenInterceptor(config),
      );
    }
    return state.onboardingApiServiceClient;
  },
};

const actions = {
  async getById(context, id) {
    try {
      const { data } = await context.getters.onboardingApiServiceClient(context)
        .get(`/fleets/${id}`);

      return data;
    } catch (error) {
      await context.dispatch(
        'globalMessages/addError',
        { message: 'fleet.listFleetError', error },
        { root: true },
      );
      throw error;
    }
  },
  async getFleets(context, {
    page, maxPerPage, dynamicSearch, paymentInstance,
  }) {
    const params = {
      page,
      maxPerPage,
      dynamicSearch,
      paymentInstance,
    };
    try {
      const { data, headers } = await context.getters.onboardingApiServiceClient(context)
        .get('/fleets', {
          params,
        });
      return {
        fleets: data,
        totalFleets: parseInt(headers['x-total-count'], 0),
      };
    } catch (error) {
      await context.dispatch(
        'globalMessages/addError',
        { message: 'fleet.listFleetError', error },
        { root: true },
      );
      throw error;
    }
  },
  // eslint-disable-next-line consistent-return
  async list(context, dynamicSearch) {
    try {
      context.commit('setListFleetInProgress', true);
      const { data } = await context.getters.onboardingApiServiceClient(context)
        .get(`/fleets?dynamicSearch=${dynamicSearch}&page=0`);
      context.commit('setFleetList', data);
      return data;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'fleet.listFleetError', error },
        { root: true },
      );

      throw new Error(error);
    } finally {
      context.commit('setListFleetInProgress', false);
    }
  },
  async getFleetWithBookingChannelConfig(context, { fleetId, bookingChannelId }) {
    try {
      const { data } = await context.getters.onboardingApiServiceClient(context)
        .get(`/fleets/${fleetId}/booking-channels/${bookingChannelId}`);
      context.commit('setSelectedFleetBookingChannelConfig', data);
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'fleet.listFleetError', error },
        { root: true },
      );
    }
  },
  async resetFleetWithBookingChannelConfig(context) {
    context.commit('setSelectedFleetBookingChannelConfig', null);
  },
  /**
   * Attach a new financial config to a fleet for it's "network" financial config.
   * @param context
   * @param fleetId
   * @param financialConfig
   * @returns {Promise<void>}
   */
  async addFleetToNetworkFinancialConfig(context, { fleetId, financialConfig }) {
    try {
      await context.getters.onboardingApiServiceClient(context)
        .post(`/fleets/${fleetId}/financial-settings/network`, financialConfig);
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'fleet.saveFleetError', error },
        { root: true },
      );
      throw error;
    }
  },
  async getFleet(context, { fleetId, webhooks }) {
    try {
      context.commit('setRetrieveFleetInProgress', true);
      const url = webhooks ? `/fleets/${fleetId}?withWebhooksData=true` : `/fleets/${fleetId}?relations[]=fleetNetworks&relations[]=fleetBookingAppConfig`;
      const { data: fleet } = await context.getters.onboardingApiServiceClient(context)
        .get(url);
      const fleetMapped = {
        credentials: { dispatchAttributes: {} },
        taxes: { numbers: {} },
        legalInformation: { enabled: false },
        connectors: { marketing: { configuration: {} } },
        dispatchInformation: { coordinates: {} },
        preauthorizationRuleStrategy: 'custom',
        networkCoordinates: {},
        ...fleet,
      };
      const paymentConnector = get(fleetMapped, 'connectors.payment');
      if (paymentConnector && !isArray(get(fleetMapped, 'connectors.payment'))) {
        fleetMapped.connectors.payment = [paymentConnector];
      }
      return fleetMapped;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'fleet.retrieveFleetError', error },
        { root: true },
      );
      return {};
    } finally {
      context.commit('setRetrieveFleetInProgress', false);
    }
  },
  async getDispatchVehicleIcons(context, { fleetId }) {
    try {
      context.commit('setRetrieveFleetInProgress', true);
      const url = `/fleets/${fleetId}/vehicle-types`;
      const { data: dispatchVehicleTypes } = await context.getters.onboardingApiServiceClient(context).get(url);
      return dispatchVehicleTypes;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'fleet.retrieveBookingAppConfigError', error },
        { root: true },
      );
      return {};
    } finally {
      context.commit('setRetrieveFleetInProgress', false);
    }
  },
  async saveSettings(context, { fleetId, bookingChannelId, settings }) {
    try {
      context.commit('setSaveFleetSettingsInProgress', true);
      await context.getters.onboardingApiServiceClient(context)
        .post(`/fleets/${fleetId}/booking-channels/${bookingChannelId}`, { finance: settings });
      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'fleet.saveFleetSuccess' },
        { root: true },
      );

      const newState = {
        ...context.state.selectedFleetBookingChannelConfig,
      };

      newState.bookingChannelConfig.finance.push(settings);

      context.commit('setSelectedFleetBookingChannelConfig', newState);
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'fleet.saveFleetError', error },
        { root: true },
      );
    } finally {
      context.commit('setSaveFleetSettingsInProgress', false);
    }
  },
  async save(context, fleetData) {
    try {
      context.commit('setSaveFleetInProgress', true);
      const fleetPayload = mapFleetPayload(fleetData);
      const { data: fleet } = await context.getters.onboardingApiServiceClient(context)
        .post('/fleets', fleetPayload);
      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'fleet.saveFleetSuccess' },
        { root: true },
      );
      return fleet;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'fleet.saveFleetError', error },
        { root: true },
      );
      return false;
    } finally {
      context.commit('setSaveFleetInProgress', false);
    }
  },
  async update(context, fleetData) {
    try {
      context.commit('setUpdateFleetInProgress', true);
      const fleetPayload = mapFleetPayload(fleetData);
      await context.getters.onboardingApiServiceClient(context)
        .put(`/fleets/${fleetData.id}`, fleetPayload);
      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'fleet.updateFleetSuccess' },
        { root: true },
      );
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'fleet.updateFleetError', error },
        { root: true },
      );
    } finally {
      context.commit('setUpdateFleetInProgress', false);
    }
  },
  async createWebhooks(context, { fleetId }) {
    try {
      await context.getters.onboardingApiServiceClient(context)
        .post(`/fleets/${fleetId}/webhooks`);
      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'fleet.saveFleetSuccess' },
        { root: true },
      );
      return true;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'fleet.saveFleetError', error },
        { root: true },
      );
      return false;
    }
  },
  // eslint-disable-next-line consistent-return
  async updateVehicleIconsInFleetBookingAppConfig(context, { fleet, listIcons, mapIcons }) {
    try {
      context.commit('setUpdateFleetInProgress', true);

      /**
       * We will send the listIcon and mapIcon separately to the backend because of the max payload size limit
       * requests array will contain the list of requests to be sent to the backend
       * requests = [
       *    'request for lisIcon',
       *    'request for mapIcon'
       * ];
       */
      const requests = [];
      if (listIcons.length) {
        requests.push(
          new Promise(async (resolve) => {
            const { data: { dispatchVehicleTypeListIcons } } = await context.getters.onboardingApiServiceClient(context)
              .post(`/fleets/${fleet.id}/booking-app-config`, {
                dispatchVehicleTypeListIcons: {
                  icons: listIcons,
                },
              });
            resolve({ dispatchVehicleTypeListIcons });
          }),
        );
      }
      if (mapIcons.length) {
        requests.push(
          new Promise(async (resolve) => {
            const { data: { dispatchVehicleTypeMapIcons } } = await context.getters.onboardingApiServiceClient(context)
              .post(`/fleets/${fleet.id}/booking-app-config`, {
                dispatchVehicleTypeMapIcons: {
                  icons: mapIcons,
                },
              });
            resolve({ dispatchVehicleTypeMapIcons });
          }),
        );
      }

      let fleetBookingAppConfig = fleet.fleetBookingAppConfig || {};
      await pMap(requests, (fleetBookingAppConfigResponse) => {
        fleetBookingAppConfig = {
          ...fleetBookingAppConfig,
          ...fleetBookingAppConfigResponse,
        };
        return fleetBookingAppConfig;
      }, { concurrency: 2 });

      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'fleet.updateBookingAppConfigSuccess' },
        { root: true },
      );
      return fleetBookingAppConfig;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'fleet.updateBookingAppConfigError', error },
        { root: true },
      );
    } finally {
      context.commit('setUpdateFleetInProgress', false);
    }
  },
  async evaluatePaymentConnectorStatus(context, { fleetId, connectedAccountId, paymentProviderName }) {
    try {
      const result = await context.getters.onboardingApiServiceClient(context)
        .post(`/fleets/${fleetId}/payment-connector-accounts/${connectedAccountId}/evaluate/${paymentProviderName}:`);

      return result.data.isValid;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'paymentConnector.evaluatePaymentConnectorStatusError', error },
        { root: true },
      );
      return false;
    }
  },
};

const mutations = {
  setFleetList(state, fleets) {
    state.list = fleets;
  },
  setSelectedFleetBookingChannelConfig(state, selectedFleetBookingChannelConfig) {
    state.selectedFleetBookingChannelConfig = selectedFleetBookingChannelConfig;
  },
  setListFleetInProgress(state, listFleetInProgress) {
    state.listFleetInProgress = listFleetInProgress;
  },
  setRetrieveFleetInProgress(state, retrieveFleetInProgress) {
    state.retrieveFleetInProgress = retrieveFleetInProgress;
  },
  setSaveFleetInProgress(state, saveFleetInProgress) {
    state.saveFleetInProgress = saveFleetInProgress;
  },
  setSaveFleetSettingsInProgress(state, saveFleetSettingsInProgress) {
    state.saveFleetSettingsInProgress = saveFleetSettingsInProgress;
  },
  setUpdateFleetInProgress(state, updateFleetInProgress) {
    state.updateFleetInProgress = updateFleetInProgress;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
