<template>
  <v-row>
    <v-col cols='12'>
      <fleet-management-form
        @save='save'
        :fleet='fleet'
        :isLoading="isLoading"
        :isUpdateForm="isUpdateForm">
      </fleet-management-form>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { DispatchEngine } from '@/core/dispatchEngine';
import FleetManagementForm from './FleetManagementForm.vue';

export default {
  name: 'fleet-management',
  components: { FleetManagementForm },
  data() {
    return {
      isFleetLoading: true,
      fleet: null,
      defaultFleet: {
        syncMode: 'ic2',
        connectors: {
          payment: [],
          marketing: {
            configuration: {},
          },
        },
        taxes: {
          numbers: {},
        },
        country: '',
        credentials: {
          dispatchAttributes: {
            dispatchId: DispatchEngine.IC2,
            apiCredentials: {},
            gatewayApiCredentials: {},
          },
        },
        preauthorizationRuleStrategy: 'default',
        preauthorizationRules: [{
          margin: { base: 'percentage', value: 0 },
          minimalAmount: 40,
          fareEstimateAmount: 1,
          type: 'DYNAMIC',
        }],
        legalInformation: { enabled: false },
        dispatchInformation: {
          coordinates: {},
        },
        networkCoordinates: {},
        allowedPaymentMethods: [],
        sendOnBehalfOf: true,
        fees: {
          prematureCancellationFee: {
            cancellationFee: null,
            driverShare: null,
            // The passenger will be charged if they cancel after X amount of time AFTER the driver has been assigned
            chargeablePostDriverAssignedDurationMinutes: null,
            // The passenger will only be charged if the time it took to assign the driver IS LESS than the specified exemptDriverAssignmentDurationSec
            exemptDriverAssignmentDurationMinutes: null,
          },
          passengerNoShowFeeConfig: {
            noShowFee: null,
            driverShare: null,

            /**
             * Only applied if the booking scheduleType is ASAP.
             *
             * The maximum number of seconds it may take for a driver to arrive at the PickupLocation
             * in order for the NoShowFee to take effect.
             *
             * If the time between creating the booking and the driver arriving is longer than
             * this duration then the NoShowFee is not applied
             * (i.e. the Passenger is EXEMPT from paying the fee).
             */
            exemptAsapDriverArrivalDurationMinutes: null,

            prebookingMaxPickupToArrivalDeltaLabelMinutes: null,

            /**
             * The minimum time the Driver must wait at the PickupLocation before declaring
             * a NoShow in order for the NoShowFee to be applied.
             *
             * Example:
             * ------------------------------------------------------
             * requiredDriverWaitingDurationAfterArrivalSec = 120
             * driverArrivedAtPickupLocationAt: 09:00:00
             * -> If the Driver declares a NoShow at 09:01:59, then the NoShowFee WILL NOT be applied.
             * -> If the Driver declares a NoShow at 09:02:00, then the NoShowFee WILL be applied.
             * ------------------------------------------------------
             */
            requiredDriverWaitingDurationAfterArrivalMinutes: null,

          },
          displayExtraTariffPrice: false,
          isFixedFarePreAuthMarginEnabled: false,
        },
        driverBookingInfoDisplayConfiguration: {
          showDriverReference: true,
          driverNameLabel: 'FIRST_NAME',
          showDriverImage: true,
          showRegistrationPlate: true,
        },
        networkRadius: 5000,
        receiptConfig: {
          disableForPayments: [],
          showAdditionalFees: true,
          useDefaultTaxMessage: true,
          customTaxesMessage: null,
        },
        waitingTimeConfiguration: {
          isEnabled: false,
          selectionType: null,
          timeThreshold: null,
        },
        pricingDisplayForPaymentMethodsConfiguration: null,
      },
    };
  },
  created() {
    this.fleet = Object.assign({}, _.cloneDeep(this.defaultFleet));
  },
  async mounted() {
    if (_.get(this.$route, 'params.id')) {
      const storedFleet = await this.$store.dispatch('fleets/getFleet', { fleetId: this.$route.params.id });
      this.fleet = Object.assign(_.cloneDeep(this.defaultFleet), storedFleet);
      this.initFleetFees({ fleet: this.fleet });
      this.initFleetDriverBookingInfoDisplayConfiguration({ fleet: this.fleet });
    }
    this.isFleetLoading = false;
  },
  watch: {
    formType() {
      this.fleet = Object.assign({}, _.cloneDeep(this.defaultFleet));
    },
  },
  computed: {
    ...mapGetters({
      fleetList: 'fleets/list',
      listFleetInProgress: 'fleets/listFleetInProgress',
      getFleetInProgress: 'fleets/getFleetInProgress',
      saveFleetInProgress: 'fleets/saveFleetInProgress',
      updateFleetInProgress: 'fleets/updateFleetInProgress',
    }),
    isLoading() {
      return this.isFleetLoading
        || this.saveFleetInProgress
        || this.updateFleetInProgress;
    },
    isUpdateForm() {
      return !!_.get(this.$route, 'params.id');
    },
  },
  methods: {
    async save(fleetData) {
      let fleet;
      if (this.isUpdateForm) {
        fleet = await this.$store.dispatch('fleets/update', fleetData);
      } else {
        fleet = await this.$store.dispatch('fleets/save', fleetData);
      }
      await this.$router.push(`/fleets/${fleet.id}`);
    },
    listFleets(dynamicSearch) {
      this.$store.dispatch('fleets/list', dynamicSearch);
    },
    initFleetFees({ fleet }) {
      const secondsToMinutes = (obj, path) => {
        if (_.get(obj, path, null) === null) {
          return null;
        }
        return _.get(obj, path, 0) / 60;
      };

      const fees = {
        prematureCancellationFee: {
          cancellationFee: _.get(fleet, 'fees.prematureCancellationFee.cancellationFee'),
          driverShare: _.get(fleet, 'fees.prematureCancellationFee.driverShare'),
          chargeablePostDriverAssignedDurationMinutes: secondsToMinutes(fleet, 'fees.prematureCancellationFee.chargeablePostDriverAssignedDurationSec'),
          exemptDriverAssignmentDurationMinutes: secondsToMinutes(fleet, 'fees.prematureCancellationFee.exemptDriverAssignmentDurationSec'),
        },
        passengerNoShowFeeConfig: {
          noShowFee: _.get(fleet, 'fees.passengerNoShowFeeConfig.noShowFee'),
          driverShare: _.get(fleet, 'fees.passengerNoShowFeeConfig.driverShare'),
          exemptAsapDriverArrivalDurationMinutes: secondsToMinutes(fleet, 'fees.passengerNoShowFeeConfig.exemptAsapDriverArrivalDurationSec'),
          prebookingMaxPickupToArrivalDeltaMinutes: secondsToMinutes(fleet, 'fees.passengerNoShowFeeConfig.prebookingMaxPickupToArrivalDeltaSec'),
          requiredDriverWaitingDurationAfterArrivalMinutes: secondsToMinutes(fleet, 'fees.passengerNoShowFeeConfig.requiredDriverWaitingDurationAfterArrivalSec'),
        },
        passengerNoShowFee: _.get(fleet, 'fees.passengerNoShowFee'),
        displayExtraTariffPrice: _.get(fleet, 'fees.displayExtraTariffPrice', false),
        isFixedFarePreAuthMarginEnabled: _.get(fleet, 'fees.isFixedFarePreAuthMarginEnabled', false),
      };
      this.$set(this.fleet, 'fees', fees);
    },
    initFleetDriverBookingInfoDisplayConfiguration({ fleet }) {
      const driverBookingInfoDisplayConfiguration = {
        showDriverReference: _.get(fleet, 'driverBookingInfoDisplayConfiguration.showDriverReference', true),
        driverNameLabel: _.get(fleet, 'driverBookingInfoDisplayConfiguration.driverNameLabel', 'FIRST_NAME'),
        showDriverImage: _.get(fleet, 'driverBookingInfoDisplayConfiguration.showDriverImage', true),
        showRegistrationPlate: _.get(fleet, 'driverBookingInfoDisplayConfiguration.showRegistrationPlate', true),
      };
      this.$set(this.fleet, 'driverBookingInfoDisplayConfiguration', driverBookingInfoDisplayConfiguration);
    },
    getMockFleet() {
      return {
        humanReadableId: 'testtaxis1',
        locale: 'en',
        ivrPhoneNumber: '+15141111235',
        supportPhoneNumber: '+15141111235',
        syncMode: 'ic2',
        dataRegion: 'eu',
        country: 'CA',
        paymentAccountId: '50361',
        paymentFailureAccountId: '56276',
        connectors: {
          payment: {
            name: 'stripe',
            instance: 'canada',
            configuration: {
              accountId: 'acct_1FGnv7K4h4GWT6nI',
              statementDescriptor: 'iCabbi Canada',
              fees: {
                fixed: 25,
                percentage: 2.4,
              },
            },
          },
          marketing: {
            name: 'voucherify',
            configuration: {
              instance: 'europe',
              applicationId: 'eeccd943-fdfb-495b-99fb-59099b98e9b7',
              clientSecret: '6e2cd09d-cd86-412e-aaf2-7a466a36156d',
            },
          },
        },
        taxes: {
          enabled: false,
          numbers: {},
        },
        credentials: {
          dispatchAttributes: {
            dispatchId: DispatchEngine.IC2,
            apiCredentials: {},
            gatewayApiCredentials: {},
          },
        },
        preauthorizationRules: [{
          margin: { base: 'percentage', value: 0 }, minimalAmount: 40, fareEstimateAmount: 1, type: 'DYNAMIC',
        }],
      };
    },
  },
};
</script>

<style scoped>

</style>
